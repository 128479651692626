svg.chart {
  cursor: default;
}

svg.chart g rect.bar {
  transition: fill 0.1s linear;
  pointer-events: none;
}

svg.chart g rect.label-rect {
  fill: rgba(0, 0, 0, 0);
}

svg.chart g:hover rect.bar {
  fill: #007bff !important;
}

svg.chart g:hover rect.label-rect {
  fill: rgba(0, 0, 0, 0.5);
}

svg.chart .label-key {
  fill: #333;
  font-size: 0.7rem;
}

svg.chart g .label-value {
  display: none;
  font-size: 0.8rem;
  fill: #fff;
}

svg.chart g:hover .label-value {
  display: block;
}
